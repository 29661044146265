/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
<template>
 <div class="d-inline-flex ms-3">
  <input v-model="noteData.path" class="me-auto from-control rounded" type="text">
  <div class="form-check form-switch ms-2">
   <input v-model="noteData.isPublic" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
   <label class="form-check-label" for="flexSwitchCheckDefault">Public</label>
  </div>
  <button class="btn btn-primary ms-2">Save</button>
  <button class="btn btn-danger ms-2">Delete</button>
 </div>
 <div class="border-bottom mt-1 mb-3"></div>
 <div class="container">
  <div class="row">
   <div class="col d-flex justify-content-center ms-0">
    <textarea class="form-control" v-model="rawData" @input="updateTextarea" style="width: 100%; overflow: hidden; resize: none;"></textarea>
   </div>
   <div class="col d-flex justify-content-center">
    <textarea disabled="disabled" class="form-control" v-bind:value="rawData" style="width: 100%; overflow: hidden; resize: none; background-color: #FFF">a</textarea>
   </div>
  </div>
 </div>
 <p>{{ noteData }}</p>
</template>

<style scoped>
</style>

<script>
 import { code, getUser } from '@/lib/auth.js'
 
 export default {
     name: 'NotesEdit',
     data() {
         return {
             rawData: '',
             noteData: {isPublic: false, allowedUsers: [], path: 'Title', data: ''}
         }
     },
     methods: {
         updateTextarea(e){
             let user = getUser()
             if (this.noteData.isPublic){
                 this.noteData.data = this.rawData
             } else {
                 this.noteData.data = code.encryptMessage(this.rawData, user.pass)
             }
             e.srcElement.style.height = Math.max(e.srcElement.scrollHeight, 200) + "px"
         }
     },
 }
</script>
